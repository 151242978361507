module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://seekinggood.net","contentApiKey":"a35b7461ade3db1cc0fde4353c"},"production":{"apiUrl":"https://seekinggood.net","contentApiKey":"a35b7461ade3db1cc0fde4353c"}},"siteConfig":{"siteUrl":"https://seekinggod.cn","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"寻求你面","siteDescriptionMeta":"要寻求耶和华与他的能力，时常寻求他的面。（诗篇105:4）","shortTitle":"寻求你面","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
